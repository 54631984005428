.delete-button
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler {
  display: none;
}
.align-icons .p-tree .p-tree-container .p-treenode .p-treenode-content {
  justify-content: center;
}
.tree-node-menu {
  .p-tree-container {
    overflow: hidden;
  }
  &.p-tree {
    background: transparent;
    color: #a3a4a7;
    border: 0 none;
    padding: 0.8rem 0.8rem 0.8rem 0;
    .p-tree-container .p-treenode {
      .p-treenode-content:focus {
        box-shadow: 0 0;
      }
      .p-treenode-content .p-tree-toggler:focus {
        box-shadow: 0 0;
      }
    }
  }
}
