.p-dialog-header {
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
}

.p-dialog-mask .p-dialog {
  max-height: 96%;
}

@media only screen and (min-width: 768px) {
  .p-dialog-header {
    padding: 20px;
    font-size: 26px;
  }
}
