.p-inputtext {
  font-size: 14px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
}

.p-paginator {
  font-size: 14px;
  .p-paginator-pages .p-paginator-page {
    font-size: 14px;
  }
}

.display-general-mindit-tables {
  .table-dropdowns .p-dropdown {
    width: 14rem;
  }

  &.mobile {
    .p-datatable {
      .p-datatable-header {
        border: none;
        padding: 0rem 0rem;
      }
    }

    .p-column-filter-row .p-column-filter-clear-button {
      margin-left: 2rem;
    }

    .p-column-filter-row {
      align-items: baseline;
    }

    .table-dropdowns {
      width: 100%;
    }

    .p-input-icon-left > .p-inputtext {
      width: 8rem;
    }

    .background-white {
      background-color: white;
      padding-bottom: 1rem;
      justify-content: center;
    }

    .search-input {
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
}

.tables-company-team {
  .p-datatable .p-datatable-thead > tr > th:nth-child(2) {
    width: 13rem;

    &.mobile {
      width: inherit;
    }
  }
}

@media only screen and (min-width: 768px) {
  .holidays-table.p-datatable {
    .p-datatable-thead > tr > th {
      padding-left: 2rem;
    }
    .p-datatable-tbody > tr > td {
      padding-left: 2rem;
    }
  }
}

.table-requests-status {
  .p-scroller {
    contain: inherit;
  }
}
.accepted {
  background-color: #c8e6c9;

  color: #256029;
}

.refused {
  background-color: #ffcdd2;
  color: #c63737;
}

.pending {
  background-color: #ffd8b2;
  color: #805b36;
}

.deleted {
  background-color: #e8e8e8;
  color: #606060;
}

.accepted,
.refused,
.pending,
.deleted {
  padding: 2px 14px;
  border-radius: 10px;
  text-align: center;
}

.p-datatable > .p-datatable-wrapper::-webkit-scrollbar {
  height: 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
}

.p-datatable > .p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d1d1d1;
}

.selection-calendar-table {
  .p-datatable {
    .p-datatable-thead > tr > th {
      border: 1px solid #dee2e6;
      padding: 0.3rem 1rem;
    }

    .p-datatable-thead tr > th:first-child {
      border-bottom: none;
    }
    
    .p-datatable-thead tr:nth-child(2) > th {
      border-top: none;
    }

    .p-datatable-tbody > tr > td {
      border: 1px solid #dee2e6;
    }
  }
}

@media screen and (max-width: 500px) {
  .p-datatable .p-paginator {
    justify-content: center;
    padding: 0.5rem 0;
  }

  .p-paginator-bottom {
    .p-dropdown {
      display: none;
    }
  }
}