.nomad-gallery {
    .p-galleria .p-galleria-thumbnail-container {
        background: none;
        padding: 0.5rem 0.25rem;
        height: 5rem;

        .p-galleria-thumbnail-item-content:focus {
            box-shadow: none;
        }
    }

    .p-galleria-item-container {
        chevronrighticon, chevronlefticon {
            svg {
                width: 2.3rem;
                height: 2.3rem;
            }
        }
    }

    .p-galleria-thumbnail-prev, .p-galleria-thumbnail-next {
        display: none;
    }

    .p-galleria-thumbnail-item {
        height: 4rem;
    }

    .p-link:focus {
        box-shadow: none;
    }
}