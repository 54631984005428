.classic-calendar-dropdown .p-dropdown.p-component {
  width: 200px;
}

p-dialog.classic-calendar-dialog .p-dialog-mask .p-dialog {
  width: 45rem;
  height: 28rem;

  .p-dialog-header {
    padding-bottom: 1rem;
  }

  .p-dialog-content {
    padding-bottom: 1rem;
  }
}

p-dialog.classic-calendar-dialog.mobile .p-dialog-mask .p-dialog {
  width: 90%;
  height: 30rem;
}

p-table.modal-table .p-datatable-wrapper .p-datatable-table {
  min-width: 40rem;
  min-height: 17rem;
}

.modal-table .p-datatable .p-datatable-thead>tr>th {
  background-color: #e01728;
  color: white;
  font-weight: normal;
  border-width: 1px;
  padding: 4px;
  text-align: center;
}

.modal-table .p-datatable.p-datatable-gridlines {
  border-color: rgb(185, 182, 182);
}

.modal-table .p-datatable .p-datatable-tbody>tr>td {
  vertical-align: top;
}

.fc-today {
  background-color: white;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  font-size: 15px;
  background-color: #e01728;
}

.fc.fc-theme-standard a.fc-col-header-cell-cushion {
  color: white;
  font-weight: normal;
}

.fc-weekend {
  background-color: hsla(0, 0%, 82%, 0.3);
  pointer-events: none;
}

.fc .fc-daygrid-day-top {
  display: block;
  padding-bottom: 1rem;
}

.fc-day-sat,
.fc-day-sun {
  .fc-weekend {
    .fc-daygrid-day-top a {
      color: rgb(169, 167, 167);
    }
  }
}

.fc-day-sat,
.fc-day-sun {
  .fc-daygrid-day-frame {
    .fc-daygrid-day-events {
      visibility: hidden;
    }
  }
}

.fc .fc-daygrid-day-frame {
  height: 8rem;

  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness a::after {
  background-color: white;
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness a::before {
  border-color: none;
}

.fc-event:focus {
  box-shadow: none;
}

.fc-event:hover {
  cursor: pointer;
}

.fc .fc-day-sat.fc-day-disabled {
  background: none;
}

.fc .fc-day-sun.fc-day-disabled {
  background: none;
}

.fc-h-event .fc-event-title-container {
  font-size: 13px;
  text-wrap: balance;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media screen and (min-width:768px) and (max-width:1050px) {
    font-size: 11px;
  }
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  margin-left: 1px;
}

.fc .fc-scroller-liquid-absolute,
.fc .fc-scroller {
  @media screen and (max-width: 500px) {
    inset: 0px;
  }

  inset: 3px;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d1d1d1;
  }
}

.fc .fc-toolbar {
  @media screen and (max-width: 500px) {
    flex-direction: row-reverse;

    .fc-toolbar-chunk:last-child {
      display: none;
    }
  }
}