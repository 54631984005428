.custom-listbox {
  .p-listbox .p-listbox-list {
    padding: 0;

    .p-listbox-item {
      padding: 0;
    }

    .p-listbox-empty-message {
      font-size: 0.875rem;
    }
  }
}