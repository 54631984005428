.p-fileupload {
    .p-fileupload-content {
        padding: 0;
    }

    .p-fileupload-row {
        >div:first-child {
            display: none;
        }
    }
}