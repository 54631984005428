label {
  font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}

.p-dropdown-items-wrapper {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d1d1d1;
  }
}

.p-multiselect-label {
  font-size: 14px;
}

.p-listbox-item {
  font-size: 14px;
}

.p-multiselect-panel {
  .p-multiselect-header {
    padding: 0.5rem 1rem;
  }

  .p-multiselect-items {
    padding: 0.5rem 0;

    .p-multiselect-item {
      font-size: 14px;
      padding: 0.5rem 1rem;
    }
  }
}

.user-multiselect {
  .p-multiselect-items-wrapper {
    max-height: 9rem !important;
  }
}

.phone-input {
  .p-inputtext {
    width: 100%;
  }
}