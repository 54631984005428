.filters-accordeon.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    .p-accordion-toggle-icon {
      position: absolute;
      right: 1rem;
    }
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0px;
    padding: 1rem 1.25rem;
    font-weight: 500;
  }
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: #fff;
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background-color: #fff;
  }
  .p-accordion-content {
    border: none;
  }
}

.accordion-design.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    background-color: #ebeeef;
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background-color: #f8f9fa;
  }
}

.accordion-header-before.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    .p-accordion-toggle-icon {
      display: none;
    }
    display: none;
  }
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: #fff;
  }
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background-color: #fff;
  }
  .p-accordion-content {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.accordion-header-after.p-accordion {
  .p-accordion-toggle-icon {
    display: none;
  }
  .p-accordion-header .p-accordion-header-link {
    background-color: #fbfbfb;
    padding: 0.7rem;
    font-weight: 500;
    color: #000;
    border-radius: 6px;
  }
  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: #fdfdfd;
  }
}

.manage-nomad-office .p-accordion {
  .p-accordion-header:not(.p-disabled) .p-accordion-header-link{
    background: none;

    &:focus{
      box-shadow: none;
    }
  }
}
