.burger-menu-sidebar {
  &.p-sidebar-left {
    background-color: #46494f;
    width: 100%;
  }

  &.p-sidebar {
    .p-sidebar-footer {
      padding: 0;
    }

    .p-sidebar-header .p-sidebar-close,
    .p-sidebar .p-sidebar-header .p-sidebar-icon {
      margin-top: auto;
      color: #fff;
    }

    .p-sidebar-content {
      padding: 1rem 0;
    }

    .p-sidebar-header {
      border-bottom: 1px solid #a3a4a7;
    }
  }
}
@media only screen and (min-width: 281px) {
  .burger-menu-sidebar.p-sidebar-left {
    width: 90%;
  }
}

@media only screen and (min-width: 576px) {
  .burger-menu-sidebar.p-sidebar-left {
    width: 60%;
  }
}
