/* You can add global styles to this file, and also import other style files */

@import "global_variables";

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.float-button {
  float: left !important;
}

@media only screen and (max-width: 767px) {
  .shadow-effect {
    box-shadow: 0 0 10px #a3a4a7;
    clip-path: inset(0px -15px 0px 0px);
  }
}

html,
body {
  font-family: $default-font;
  color: $default-text-color;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}
