.p-calendar-w-btn .p-datepicker-trigger {
  border-top-right-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
  margin-left: -2px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  .p-datepicker-year,
  .p-datepicker-month {
    font-size: 14px;
  }
}

.p-datepicker table th {
  font-size: 14px;
}

table.p-datepicker-calendar td {
  padding: 0;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .p-calendar-w-btn .p-datepicker-trigger {
    padding: 0.6rem 0;
    width: 2.5rem;
  }
}

.make-a-request-calendars {
  td {
    font-weight: bold;
  }

  .p-disabled {
    color: grey;
  }
}

.nomad-calendar {
  td {
    font-weight: bold;
  }

  span.p-disabled {
    font-weight: 300;
  }
}
