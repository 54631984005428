.p-message {
    .p-message-summary {
        font-size: 0.9rem;
    }

    .p-message-detail {
        font-size: 0.875rem;
    }

    .p-message-wrapper {
        padding: 0.5rem;
    }
}