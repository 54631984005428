.edit-user {
  .p-calendar {
    width: 100%;
  }
  .p-multiselect .p-multiselect-clearable {
    width: 100%;
  }
  .p-dropdown {
    width: 100%;
  }

  .p-icon-wrapper{
    display: flex;
  }
}
